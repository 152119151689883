import React from "react";

// Imports will go in this way
const Courses = React.lazy(() => import("./pages/Courses"));
const AddCourse = React.lazy(() => import("./pages//Courses/AddCourse"));
const UpdateCourse = React.lazy(() => import("./pages//Courses/UpdateCourse"));

const CourceCertificates = React.lazy(() =>
  import("./pages/Courses/Certificates")
);
const AddCourceCertificate = React.lazy(() =>
  import("./pages/Courses/Certificates/AddCertificate")
);
const UpdateCourceCertificate = React.lazy(() =>
  import("./pages/Courses/Certificates/UpdateCertificate")
);

const CourseFields = React.lazy(() => import("./pages/Courses/Fields"));
const AddCourseField = React.lazy(() =>
  import("./pages/Courses/Fields/AddField")
);
const UpdateCourseField = React.lazy(() =>
  import("./pages/Courses/Fields/UpdateField")
);

const CoursePrograms = React.lazy(() => import("./pages/Courses/Programs"));
const AddCourseProgram = React.lazy(() =>
  import("./pages/Courses/Programs/AddProgram")
);
const UpdateCourseProgram = React.lazy(() =>
  import("./pages/Courses/Programs/UpdateProgram")
);

const CourseLessons = React.lazy(() => import("./pages/Courses/Lessons"));
const AddCourseLesson = React.lazy(() =>
  import("./pages/Courses/Lessons/AddLesson")
);
const UpdateCourseLesson = React.lazy(() =>
  import("./pages/Courses/Lessons/UpdateLesson")
);

const CourseMentors = React.lazy(() => import("./pages/Courses/Mentors"));
const AddCourseMentor = React.lazy(() =>
  import("./pages/Courses/Mentors/AddMentor")
);
const UpdateCourseMentor = React.lazy(() =>
  import("./pages/Courses/Mentors/UpdateMentor")
);

const CourseTests = React.lazy(() => import("./pages/Courses/Tests"));
const AddCourseTest = React.lazy(() => import("./pages/Courses/Tests/AddTest"));
const UpdateCourseTest = React.lazy(() =>
  import("./pages/Courses/Tests/UpdateTest")
);

const EditPassword = React.lazy(() => import("./pages/Admin/EditPassword"));

const Districts = React.lazy(() => import("./pages/Admin/Districts"));
const AddDistrict = React.lazy(() =>
  import("./pages/Admin/Districts/AddDistrict")
);
const UpdateDistrict = React.lazy(() =>
  import("./pages/Admin/Districts/UpdateDistrict")
);

const Regions = React.lazy(() => import("./pages/Admin/Regions"));
const AddRegion = React.lazy(() => import("./pages/Admin/Regions/AddRegion"));
const UpdateRegion = React.lazy(() =>
  import("./pages/Admin/Regions/UpdateRegion")
);

const Specialities = React.lazy(() =>
  import("./pages/Profession/Specialities")
);
const AddSpeciality = React.lazy(() =>
  import("./pages/Profession/Specialities/AddSpeciality")
);
const UpdateSpeciality = React.lazy(() =>
  import("./pages/Profession/Specialities/UpdateSpeciality")
);

const PersonParameters = React.lazy(() =>
  import("./pages/Person/PersonParameters")
);
const AddPersonParameter = React.lazy(() =>
  import("./pages/Person/PersonParameters/AddPersonParameter")
);
const UpdatePersonParameter = React.lazy(() =>
  import("./pages/Person/PersonParameters/UpdatePersonParameter")
);

const PersonTests = React.lazy(() => import("./pages/Person/PersonTests"));
const AddPersonTest = React.lazy(() =>
  import("./pages/Person/PersonTests/AddPersonTest")
);
const UpdatePersonTest = React.lazy(() =>
  import("./pages/Person/PersonTests/UpdatePersonTest")
);

const PersonTypes = React.lazy(() => import("./pages/Person/PersonTypes"));
const AddPersonType = React.lazy(() =>
  import("./pages/Person/PersonTypes/AddPersonType")
);
const UpdatePersonType = React.lazy(() =>
  import("./pages/Person/PersonTypes/UpdatePersonType")
);

const Professions = React.lazy(() => import("./pages/Profession/Professions"));
const AddProfession = React.lazy(() =>
  import("./pages/Profession/Professions/AddProfession")
);
const UpdateProfession = React.lazy(() =>
  import("./pages/Profession/Professions/UpdateProfession")
);

const Abilities = React.lazy(() =>
  import("./pages/Profession/Professions/Abilities")
);
const AddAbility = React.lazy(() =>
  import("./pages/Profession/Professions/Abilities/AddAbility")
);
const UpdateAbility = React.lazy(() =>
  import("./pages/Profession/Professions/Abilities/UpdateAbility")
);

const GoodAspects = React.lazy(() =>
  import("./pages/Profession/Professions/GoodAspects")
);
const AddGoodAspect = React.lazy(() =>
  import("./pages/Profession/Professions/GoodAspects/AddGoodAspect")
);
const UpdateGoodAspect = React.lazy(() =>
  import("./pages/Profession/Professions/GoodAspects/UpdateGoodAspect")
);

const NegAspects = React.lazy(() =>
  import("./pages/Profession/Professions/NegAspects")
);
const AddNegAspect = React.lazy(() =>
  import("./pages/Profession/Professions/NegAspects/AddNegAspect")
);
const UpdateNegAspect = React.lazy(() =>
  import("./pages/Profession/Professions/NegAspects/UpdateNegAspect")
);

const Specialists = React.lazy(() =>
  import("./pages/Profession/Professions/Specialists")
);
const AddSpecialist = React.lazy(() =>
  import("./pages/Profession/Professions/Specialists/AddSpecialist")
);
const UpdateSpecialist = React.lazy(() =>
  import("./pages/Profession/Professions/Specialists/UpdateSpecialist")
);

const WorkPlaces = React.lazy(() =>
  import("./pages/Profession/Professions/WorkPlaces")
);
const AddWorkPlace = React.lazy(() =>
  import("./pages/Profession/Professions/WorkPlaces/AddWorkPlace")
);

const ProfessionTests2 = React.lazy(() =>
  import("./pages/Profession/ProfessionTests2")
);
const AddProfessionTest2 = React.lazy(() =>
  import("./pages/Profession/ProfessionTests2/AddProfessionTest2")
);
const UpdateProfessionTest2 = React.lazy(() =>
  import("./pages/Profession/ProfessionTests2/UpdateProfessionTest2")
);

const ProfessionTests3 = React.lazy(() =>
  import("./pages/Profession/ProfessionTests3")
);
const AddProfessionTest3 = React.lazy(() =>
  import("./pages/Profession/ProfessionTests3/AddProfessionTest3")
);
const UpdateProfessionTest3 = React.lazy(() =>
  import("./pages/Profession/ProfessionTests3/UpdateProfessionTest3")
);

const ProfessionTypes = React.lazy(() =>
  import("./pages/Profession/ProfessionTypes")
);
const AddProfessionType = React.lazy(() =>
  import("./pages/Profession/ProfessionTypes/AddProfessionType")
);
const UpdateProfessionType = React.lazy(() =>
  import("./pages/Profession/ProfessionTypes/UpdateProfessionType")
);

const SpSubjects = React.lazy(() => import("./pages/Profession/SpSubjects"));
const AddSpSubject = React.lazy(() =>
  import("./pages/Profession/SpSubjects/AddSpSubject")
);
const UpdateSpSubject = React.lazy(() =>
  import("./pages/Profession/SpSubjects/UpdateSpSubject")
);

const Files = React.lazy(() => import("./pages/Files"));

// Routes will go in this way
const routes = [
  {
    path: "/editPassword",
    exact: true,
    name: "Parolni o'zgartirish",
    component: EditPassword,
    bread: [{ path: "/editPassword", name: "Parolni o'zgartirish" }],
  },
  // Regions
  {
    path: "/regions",
    exact: true,
    name: "Viloyatlar",
    component: Regions,
    bread: [{ path: "/regions", name: "Viloyatlar" }],
  },
  {
    path: "/regions/add",
    exact: true,
    name: "Viloyat qo'shish",
    component: AddRegion,
    bread: [
      { path: "/regions", name: "Viloyatlar" },
      { path: "/regions/add", name: "Viloyat qo'shish" },
    ],
  },
  {
    path: "/regions/update/:id",
    name: "Viloyatni yangilash",
    component: UpdateRegion,
    bread: [
      { path: "/regions", name: "Viloyatlar" },
      { path: "/regions/update/:id", name: "Viloyatni yangilash" },
    ],
  },

  //   Districts
  {
    path: "/districts",
    exact: true,
    name: "Tumanlar",
    component: Districts,
    bread: [{ path: "/districts", name: "Tumanlar" }],
  },
  {
    path: "/districts/add",
    exact: true,
    name: "Tuman qo'shish",
    component: AddDistrict,
    bread: [
      { path: "/districts", name: "Tumanlar" },
      { path: "/districts/add", name: "Tuman qo'shish" },
    ],
  },
  {
    path: "/districts/update/:regionId/:id",
    name: "Tumanni yangilash",
    component: UpdateDistrict,
    bread: [
      { path: "/districts", name: "Tumanlar" },
      { path: "/districts/update/:regionId/:id", name: "Tumanni yangilash" },
    ],
  },

  //   Specialities
  {
    path: "/specialities",
    exact: true,
    name: "Sohalar",
    component: Specialities,
    bread: [{ path: "/specialities", name: "Sohalar" }],
  },
  {
    path: "/specialities/add",
    exact: true,
    name: "Soha qo'shish",
    component: AddSpeciality,
    bread: [
      { path: "/specialities", name: "Sohalar" },
      { path: "/specialities/add", name: "Soha qo'shish" },
    ],
  },
  {
    path: "/specialities/update/:id",
    name: "Sohani yangilash",
    component: UpdateSpeciality,
    bread: [
      { path: "/specialities", name: "Sohalar" },
      { path: "/:id", name: "Sohani yangilash" },
    ],
  },

  // Courses
  {
    path: "/courses",
    exact: true,
    name: "Kurslar",
    component: Courses,
    bread: [{ path: "/courses", name: "Kurslar" }],
  },
  {
    path: "/courses/add",
    exact: true,
    name: "Kurs Qo'shish",
    component: AddCourse,
    bread: [
      { path: "/courses", name: "Kurslar" },
      { path: "/courses/add", name: "Kurs Qo'shish" },
    ],
  },
  {
    path: "/courses/update/:id",
    name: "Kursni yangilash",
    component: UpdateCourse,
    bread: [
      { path: "/courses", name: "Kurslar" },
      { path: "/courses/update/:id", name: "Kursni yangilash" },
    ],
  },

  // Cource Certificates
  {
    path: "/courses/certificates",
    exact: true,
    name: "Sertifikatlar",
    component: CourceCertificates,
    bread: [{ path: "/courses/certificates", name: "Sertifikatlar" }],
  },
  {
    path: "/courses/certificates/add",
    exact: true,
    name: "Sertifikat qo'shish",
    component: AddCourceCertificate,
    bread: [
      { path: "/courses/certificates", name: "Sertifikatlar" },
      { path: "/courses/certificates/add", name: "Sertifikat qo'shish" },
    ],
  },
  {
    path: "/courses/certificates/update/:id",
    name: "Sertifikatni yangilash",
    component: UpdateCourceCertificate,
    bread: [
      { path: "/courses/certificates", name: "Sertifikatlar" },
      {
        path: "/courses/certificates/update/:id",
        name: "Sertifikatni yangilash",
      },
    ],
  },

  // Course Fields
  {
    path: "/courses/fields",
    exact: true,
    name: "Kurs sohalari",
    component: CourseFields,
    bread: [{ path: "/courses/fields", name: "Kurs sohalari" }],
  },
  {
    path: "/courses/fields/add",
    exact: true,
    name: "Kurs sohasini qo'shish",
    component: AddCourseField,
    bread: [
      { path: "/courses/fields", name: "Kurs sohalari" },
      { path: "/courses/fields/add", name: "Kurs sohasini qo'shish" },
    ],
  },
  {
    path: "/courses/fields/update/:id",
    name: "Kurs sohasini yangilash",
    component: UpdateCourseField,
    bread: [
      { path: "/courses/fields", name: "Kurs sohalari" },
      { path: "/courses/fields/update/:id", name: "Kurs sohasini yangilash" },
    ],
  },

  // Course Programs
  {
    path: "/courses/programs",
    exact: true,
    name: "Kurs dasturlari",
    component: CoursePrograms,
    bread: [{ path: "/courses/programs", name: "Kurs dasturlari" }],
  },
  {
    path: "/courses/programs/add",
    exact: true,
    name: "Kurs dasturini qo'shish",
    component: AddCourseProgram,
    bread: [
      { path: "/courses/programs", name: "Kurs dasturlari" },
      { path: "/courses/programs/add", name: "Kurs dasturini qo'shish" },
    ],
  },
  {
    path: "/courses/programs/update/:id",
    name: "Kurs dasturini yangilash",
    component: UpdateCourseProgram,
    bread: [
      { path: "/courses/programs", name: "Kurs dasturlari" },
      {
        path: "/courses/programs/update/:id",
        name: "Kurs dasturini yangilash",
      },
    ],
  },

  // Course Lessons
  {
    path: "/courses/lessons",
    exact: true,
    name: "Darslar",
    component: CourseLessons,
    bread: [{ path: "/courses/lessons", name: "Darslar" }],
  },
  {
    path: "/courses/lessons/add/:id",
    exact: true,
    name: "Dars qo'shish",
    component: AddCourseLesson,
    bread: [
      { path: "/courses/lessons", name: "Darslar" },
      { path: "/courses/lessons/add", name: "Dars qo'shish" },
    ],
  },
  {
    path: "/courses/lessons/update/:id",
    name: "Darsni yangilash",
    component: UpdateCourseLesson,
    bread: [
      { path: "/courses/lessons", name: "Darslar" },
      { path: "/courses/lessons/update/:id", name: "Darsni yangilash" },
    ],
  },

  // Course Mentors
  {
    path: "/courses/mentors",
    exact: true,
    name: "Mentorlar",
    component: CourseMentors,
    bread: [{ path: "/courses/mentors", name: "Mentorlar" }],
  },
  {
    path: "/courses/mentors/add",
    exact: true,
    name: "Mentor qo'shish",
    component: AddCourseMentor,
    bread: [
      { path: "/courses/mentors", name: "Mentorlar" },
      { path: "/courses/mentors/add", name: "Mentor qo'shish" },
    ],
  },
  {
    path: "/courses/mentors/update/:id",
    name: "Mentorni yangilash",
    component: UpdateCourseMentor,
    bread: [
      { path: "/courses/mentors", name: "Mentorlar" },
      { path: "/courses/mentors/update/:id", name: "Mentorni yangilash" },
    ],
  },

  // Course Tests
  {
    path: "/courses/tests",
    exact: true,
    name: "Testlar",
    component: CourseTests,
    bread: [{ path: "/courses/tests", name: "Testlar" }],
  },
  {
    path: "/courses/tests/add",
    exact: true,
    name: "Test qo'shish",
    component: AddCourseTest,
    bread: [
      { path: "/courses/tests", name: "Testlar" },
      { path: "/courses/tests/add", name: "Test qo'shish" },
    ],
  },
  {
    path: "/courses/tests/update/:id",
    name: "Testni yangilash",
    component: UpdateCourseTest,
    bread: [
      { path: "/courses/tests", name: "Testlar" },
      { path: "/courses/tests/update/:id", name: "Testni yangilash" },
    ],
  },

  //   Person Parameters
  {
    path: "/personParameters",
    exact: true,
    name: "Shaxs parametrlari",
    component: PersonParameters,
    bread: [{ path: "/personParameters", name: "Shaxs parametrlari" }],
  },
  {
    path: "/personParameters/add",
    exact: true,
    name: "Shaxs parametrini qo'shish",
    component: AddPersonParameter,
    bread: [
      { path: "/personParameters", name: "Shaxs parametrlari" },
      { path: "/personParameters/add", name: "Shaxs parametrini qo'shish" },
    ],
  },
  {
    path: "/personParameters/update/:id",
    name: "Shaxs parametrini yangilash",
    component: UpdatePersonParameter,
    bread: [
      { path: "/personParameters", name: "Shaxs parametrlari" },
      {
        path: "/personParameters/update/:id",
        name: "Shaxs parametrini yangilash",
      },
    ],
  },

  //   Person Tests
  {
    path: "/personTests",
    exact: true,
    name: "Shaxs testlari",
    component: PersonTests,
    bread: [{ path: "/personTests", name: "Shaxs testlari" }],
  },
  {
    path: "/personTests/add",
    exact: true,
    name: "Shaxs testini qo'shish",
    component: AddPersonTest,
    bread: [
      { path: "/personTests", name: "Shaxs testlari" },
      { path: "/personTests/add", name: "Shaxs testini qo'shish" },
    ],
  },
  {
    path: "/personTests/update/:id",
    name: "Shaxs testini yangilash",
    component: UpdatePersonTest,
    bread: [
      { path: "/personTests", name: "Shaxs testlari" },
      { path: "/personTests/update/:id", name: "Shaxs testini yangilash" },
    ],
  },

  //   Person Types
  {
    path: "/personTypes",
    exact: true,
    name: "Shaxs turlari",
    component: PersonTypes,
    bread: [{ path: "/personTypes", name: "Shaxs turlari" }],
  },
  {
    path: "/personTypes/add",
    exact: true,
    name: "Shaxs turini qo'shish",
    component: AddPersonType,
    bread: [
      { path: "/personTypes", name: "Shaxs turlari" },
      { path: "/personTypes/add", name: "Shaxs turini qo'shish" },
    ],
  },
  {
    path: "/personTypes/update/:id",
    name: "Shaxs turini yangilash",
    component: UpdatePersonType,
    bread: [
      { path: "/personTypes", name: "Shaxs turlari" },
      { path: "/personTypes/update/:id", name: "Shaxs turini yangilash" },
    ],
  },

  //   Professions
  {
    path: "/professions",
    exact: true,
    name: "Kasblar",
    component: Professions,
    bread: [{ path: "/professions", name: "Kasblar" }],
  },
  {
    path: "/professions/add",
    exact: true,
    name: "Kasbni qo'shish",
    component: AddProfession,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/add", name: "Kasbni qo'shish" },
    ],
  },
  {
    path: "/professions/update/:id",
    name: "Kasbni yangilash",
    component: UpdateProfession,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/update/:id", name: "Kasbni yangilash" },
    ],
  },

  // Abilities
  {
    path: "/professions/abilities/:professionId",
    name: "Qobiliyatlar",
    component: Abilities,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/abilities", name: "Qobiliyatlar" },
    ],
  },
  {
    path: "/professions/abilities/:professionId/add",
    name: "Qobiliyatni qo'shish",
    component: AddAbility,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/abilities", name: "Qobiliyatlar" },
    ],
  },
  {
    path: "/professions/abilities/:professionId/update/:id",
    name: "Qobiliyatni yangilash",
    component: UpdateAbility,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/abilities", name: "Qobiliyatlar" },
    ],
  },

  // Good Aspects
  {
    path: "/professions/goodAspects/:professionId",
    name: "Yaxshi xususiyatlari",
    component: GoodAspects,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/goodAspects", name: "Yaxshi jihatlari" },
    ],
  },
  {
    path: "/professions/goodAspects/:professionId/add",
    name: "Xususiyatni qo'shish",
    component: AddGoodAspect,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/goodAspects", name: "Yaxshi jihatlari" },
    ],
  },
  {
    path: "/professions/goodAspects/:professionId/update/:id",
    name: "Xususiyatni yangilash",
    component: UpdateGoodAspect,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/goodAspects", name: "Yaxshi jihatlari" },
    ],
  },

  // Negative Aspects
  {
    path: "/professions/negativeAspects/:professionId",
    name: "Yomon xususiyatlari",
    component: NegAspects,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/negativeAspects", name: "Yomon jihatlari" },
    ],
  },
  {
    path: "/professions/negativeAspects/:professionId/add",
    name: "Xususiyatni qo'shish",
    component: AddNegAspect,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/negativeAspects", name: "Yomon jihatlari" },
    ],
  },
  {
    path: "/professions/negativeAspects/:professionId/update/:id",
    name: "Xususiyatni yangilash",
    component: UpdateNegAspect,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/negativeAspects", name: "Yomon jihatlari" },
    ],
  },

  // Specialists
  {
    path: "/professions/specialists/:professionId",
    name: "Kasb mutaxassislari",
    component: Specialists,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/specialists", name: "Soha mutaxassislari" },
    ],
  },
  {
    path: "/professions/specialists/:professionId/add",
    name: "Mutaxassis qo'shish",
    component: AddSpecialist,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/specialists", name: "Soha mutaxassislari" },
    ],
  },
  {
    path: "/professions/specialists/:professionId/update/:id",
    name: "Mutaxassisni yangilash",
    component: UpdateSpecialist,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/specialists", name: "Soha mutaxassislari" },
    ],
  },

  // WorkPlaces
  {
    path: "/professions/workPlaces/:professionId",
    name: "Ish joylari",
    component: WorkPlaces,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/workPlaces", name: "Ish joylari" },
    ],
  },
  {
    path: "/professions/workPlaces/:professionId/add",
    name: "Ish joyini qo'shish",
    component: AddWorkPlace,
    bread: [
      { path: "/professions", name: "Kasblar" },
      { path: "/professions/workPlaces", name: "Ish joylari" },
    ],
  },

  //   Profession Tests 2
  {
    path: "/professionTests2",
    exact: true,
    name: "Kasbga yo'naltiruvchi testlar",
    component: ProfessionTests2,
    bread: [
      { path: "/professionTests2", name: "Kasbga yo'naltiruvchi testlar" },
    ],
  },
  {
    path: "/professionTests2/add",
    exact: true,
    name: "Testni qo'shish",
    component: AddProfessionTest2,
    bread: [
      { path: "/professionTests2", name: "Kasbga yo'naltiruvchi testlar" },
      { path: "/professionTests2/add", name: "Testni qo'shish" },
    ],
  },
  {
    path: "/professionTests2/update/:id",
    name: "Testni yangilash",
    component: UpdateProfessionTest2,
    bread: [
      { path: "/professionTests2", name: "Kasbga yo'naltiruvchi testlar" },
      { path: "/professionTests2/update/:id", name: "Testni yangilash" },
    ],
  },

  //   Profession Tests 3
  {
    path: "/professionTests3",
    exact: true,
    name: "Kasbiy o'sish testlari",
    component: ProfessionTests3,
    bread: [{ path: "/professionTests3", name: "Kasbiy o'sish testlari" }],
  },
  {
    path: "/professionTests3/add",
    exact: true,
    name: "Testni qo'shish",
    component: AddProfessionTest3,
    bread: [
      { path: "/professionTests3", name: "Kasbiy o'sish testlari" },
      { path: "/professionTests3/add", name: "Testni qo'shish" },
    ],
  },
  {
    path: "/professionTests3/update/:id",
    name: "Testni yangilash",
    component: UpdateProfessionTest3,
    bread: [
      { path: "/professionTests3", name: "Kasbiy o'sish testlari" },
      { path: "/professionTests3/update/:id", name: "Testni yangilash" },
    ],
  },

  //   Profession Types
  {
    path: "/professionTypes",
    exact: true,
    name: "Kasb turlari",
    component: ProfessionTypes,
    bread: [{ path: "/professionTypes", name: "Kasb turlari" }],
  },
  {
    path: "/professionTypes/add",
    exact: true,
    name: "Kasb turini qo'shish",
    component: AddProfessionType,
    bread: [
      { path: "/professionTypes", name: "Kasb turlari" },
      { path: "/professionTypes/add", name: "Kasb turini qo'shish" },
    ],
  },
  {
    path: "/professionTypes/update/:id",
    name: "Kasb turini yangilash",
    component: UpdateProfessionType,
    bread: [
      { path: "/professionTypes", name: "Kasb turlari" },
      { path: "/professionTypes/update/:id", name: "Kasb turini yangilash" },
    ],
  },

  //   Sp Subjects
  {
    path: "/spSubjects",
    exact: true,
    name: "Motivatsiya sohalari",
    component: SpSubjects,
    bread: [{ path: "/spSubjects", name: "Motivatsiya sohalari" }],
  },
  {
    path: "/spSubjects/add",
    exact: true,
    name: "Motivatsiya sohasini qo'shish",
    component: AddSpSubject,
    bread: [
      { path: "/spSubjects", name: "Motivatsiya sohalari" },
      { path: "/spSubjects/add", name: "Motivatsiya sohasini qo'shish" },
    ],
  },
  {
    path: "/spSubjects/update/:id",
    name: "Motivatsiya sohasini yangilash",
    component: UpdateSpSubject,
    bread: [
      { path: "/spSubjects", name: "Motivatsiya sohalari" },
      {
        path: "/spSubjects/update/:id",
        name: "Motivatsiya sohasini yangilash",
      },
    ],
  },

  //   Files
  {
    path: "/files",
    exact: true,
    name: "Fayllar",
    component: Files,
    bread: [{ path: "/files", name: "Fayllar" }],
  },
];
export default routes;

import React from "react";
import { Button, Layout } from "antd";
import { LogoutOutlined, EditOutlined } from "@ant-design/icons";
import { deleteCookie, userAccessTokenName } from "../utils/useCookies";
import { Link } from "react-router-dom";

const Header = () => {
  const logout = () => {
    deleteCookie(userAccessTokenName);
    window.location = "/login";
  };

  return (
    <Layout.Header
      className="site-layout-background"
      style={{
        padding: "0 12px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      <Button type="primary" style={{ marginRight: "12px" }}>
        <Link to={"/editPassword"}>
          <EditOutlined />
        </Link>
      </Button>
      <Button type="primary" danger onClick={logout}>
        <LogoutOutlined />
      </Button>
    </Layout.Header>
  );
};

export default Header;

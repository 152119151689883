import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Layout } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  GlobalOutlined,
  FolderOpenOutlined,
  FilePdfOutlined,
  UserOutlined,
} from "@ant-design/icons";

import classes from "./layout.module.scss";

const menu = [
  {
    id: 1,
    title: "Bosh sahifa",
    icon: <DesktopOutlined />,
    url: "/",
  },
  {
    id: 2,
    title: "Admin",
    icon: <GlobalOutlined />,
    children: [
      {
        id: 3,
        title: "Viloyatlar",
        url: "/regions",
      },
      {
        id: 4,
        title: "Tumanlar",
        url: "/districts",
      },
    ],
  },
  {
    id: 5,
    title: "Kasblar",
    icon: <FolderOpenOutlined />,
    children: [
      {
        id: 6,
        title: "Sohalar",
        url: "/specialities",
      },
      {
        id: 7,
        title: "Kasblar",
        url: "/professions",
      },
      {
        id: 8,
        title: "Kasb turlari",
        url: "/professionTypes",
      },
      {
        id: 9,
        title: "Motivatsiya sohalari",
        url: "/spSubjects",
      },
      {
        id: 10,
        title: "Kasbiy o'sish testlari",
        url: "/professionTests3",
      },
      {
        id: 11,
        title: "Kasbga yo'naltiruvchi testlar",
        url: "/professionTests2",
      },
    ],
  },
  {
    id: 12,
    title: "Shaxsiyat",
    icon: <UserOutlined />,
    children: [
      {
        id: 13,
        title: "Shaxs parametrlari",
        url: "/personParameters",
      },
      {
        id: 14,
        title: "Shaxs turlari",
        url: "/personTypes",
      },
      {
        id: 15,
        title: "Shaxsiyat testlari",
        url: "/personTests",
      },
    ],
  },
  {
    id: 16,
    title: "Kurslar",
    icon: <PieChartOutlined />,
    children: [
      {
        id: 17,
        title: "Kurslar",
        url: "/courses",
      },
      {
        id: 18,
        title: "Darslar",
        url: "/courses/lessons",
      },
      {
        id: 19,
        title: "Mentorlar",
        url: "/courses/mentors",
      },
      {
        id: 20,
        title: "Sertifikatlar",
        url: "/courses/certificates",
      },
      {
        id: 21,
        title: "Yo'nalishlar",
        url: "/courses/fields",
      },
      {
        id: 22,
        title: "Dasturlar",
        url: "/courses/programs",
      },
      // {
      //   id: 22,
      //   title: "Testlar",
      //   url: "/courses/tests",
      // },
    ],
  },
  {
    id: 23,
    title: "Fayllar",
    icon: <FilePdfOutlined />,
    url: "/files",
  },
];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <h2
        className={`${classes.sidebarTitle} ant-typography`}
        style={{ margin: 0 }}
      >
        <Link to="/">Prof.uz</Link>
      </h2>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        {menu.map((item) =>
          !item.children ? (
            <Menu.Item key={item.id} icon={item.icon}>
              <Link to={item.url}>{item.title}</Link>
            </Menu.Item>
          ) : (
            <Menu.SubMenu key={item.id} icon={item.icon} title={item.title}>
              {item.children.map((child) => (
                <Menu.Item key={child.id}>
                  <Link to={child.url}>{child.title}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        )}
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;

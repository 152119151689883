import React, { Component } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import routes from "./routes";
import { checkCookie, userAccessTokenName } from "./utils/useCookies";
import CustomLayout from "./containers";

const loading = () => <CustomLayout>Загрузка...</CustomLayout>;

// Pages
const Login = React.lazy(() => import("./pages/Login"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading()}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={
                !checkCookie(userAccessTokenName) ? (
                  <Login />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  checkCookie(userAccessTokenName) ? (
                    <CustomLayout route={route}>
                      <route.component />
                    </CustomLayout>
                  ) : (
                    <Navigate replace to="/login" />
                  )
                }
                exact={route.exact}
              />
            ))}
            <Route
              path="/"
              name="Home"
              element={
                checkCookie(userAccessTokenName) ? (
                  <CustomLayout>
                    <Home />
                  </CustomLayout>
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route
              path="*"
              name="Home"
              element={
                checkCookie(userAccessTokenName) ? (
                  <CustomLayout>
                    <NotFound />
                  </CustomLayout>
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;

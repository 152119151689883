import React from "react";
import { Link } from "react-router-dom";
import { Layout, Breadcrumb } from "antd";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

import classes from "./layout.module.scss";

const { Content } = Layout;

const CustomLayout = ({ route, children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Header />
        <Breadcrumb className={classes.breadcrumb}>
          <Breadcrumb.Item className={classes.breadcrumbItem}>
            <Link to="/">Bosh sahifa</Link>
          </Breadcrumb.Item>
          {route?.bread &&
            route.bread.map((el, index) => (
              <Breadcrumb.Item key={index} className={classes.breadcrumbItem}>
                {index + 1 !== route.bread.length ? (
                  <Link to={el.path}>{el.name}</Link>
                ) : (
                  <span className={classes.breadcrumbSpan}>{el.name}</span>
                )}
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
        <Content style={{ margin: "0 16px" }}>{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
